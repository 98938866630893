import * as React from 'react'
import { Link, PageProps, navigate } from 'gatsby'

import { getRedirectLanguage } from '../utils'
import Layout from '../containers/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const ThanksPage: React.FC<PageProps> = ({ location, pageContext }) => {
  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (location.pathname.startsWith('/en/') && urlLang === '/') {
      const newPathname = location.pathname.replace('/en/', '/')
      void navigate(newPathname)
    }
  }, [location.pathname])

  return (
    <Layout id="content" subtitle="Contact" pageContext={pageContext}>
      <section id="top">
        <Header location={location} pageContext={pageContext} />

        <div className="inner">
          <h1 className="title fadeup">Contact</h1>
        </div>
      </section>

      <div className="content-wrap">
        <section className="thanks">
          <h2>Your email has been sent.</h2>
          <p>
            Thank you very much for your inquiry.
            <br />
            We will reply to your message as soon as possible.
          </p>
          <button className="btn-black" type="button">
            <Link to="/">Back to top</Link>
          </button>
        </section>
      </div>

      <Footer location={location} />
    </Layout>
  )
}

export default ThanksPage
